import { useState, useEffect, useCallback, useRef } from "react";
import { isEqual } from "lodash";
import { styles } from "./styles";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { AxiosError } from "axios";
import { Bible, BiblesProps } from "./BiblesInterfaces";
import BibleComponent from "./Bible";
import useBibleService from "../../services/BibleService";
import { useUserInfo } from "../../provider/UserProvider";
import useStockNumberService from "../../services/StockNumberService";

interface ConfirmDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialogToDeleteBible = ({
  open,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => (
  <Dialog open={open}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete this Bible?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} color="primary">
        Confirm
      </Button>
      <Button onClick={onCancel} color="secondary" autoFocus>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

const ConfirmDialogToMoveBible = ({
  open,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => (
  <Dialog open={open}>
    <DialogTitle>Confirm Move</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to move this Bible to another language?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} color="primary">
        Confirm
      </Button>
      <Button onClick={onCancel} color="secondary" autoFocus>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

const ConfirmDialogToCreateCovBible = ({
  open,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => (
  <Dialog open={open}>
    <DialogTitle>Confirm Covenant Film</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This appears to be a bible for a Covenant Film. We can automatically
        create the Stocknumber and Video Media ID. Note that if this is not a
        Covenant Film bible, it may be confusing to end the bible with COV.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} color="primary">
        Generate Covenant Film metadata
      </Button>
      <Button onClick={onCancel} color="secondary" autoFocus>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

const checkIfBibleIsToChangeLanguage = (bibleId: string, bibles: Bible[]) => {
  return bibles.some(
    (bible: Bible) => bible.id === bibleId && bible.moveToLanguage,
  );
};

const checkIfBibleIsNew = (bibleId: string, bibles: Bible[]) => {
  return bibles.some((bible: Bible) => bible.id === bibleId && bible.isNew);
};

const isDuplicateId = (bibles: Bible[], bibleId: string, index: number) => {
  return bibles?.some((element, searchIndex) => {
    return (
      searchIndex !== index &&
      element.id.toLowerCase().trim() === bibleId.toLowerCase().trim()
    );
  });
};

const BIBLE_SUFFIX_COV = "COV";

const isCovBible = (bibleId: string) => {
  return bibleId.length > 5 && bibleId.endsWith(BIBLE_SUFFIX_COV);
};

const Bibles = ({
  languageId,
  bibles: retrievedBibles,
  getLanguageDetails,
  selectedBible,
  setSelectedBible,
  showMessage,
  languageIso,
}: BiblesProps) => {
  const { getBible, createBible, updateBible, deleteBible } = useBibleService();

  const [dialogState, setDialogState] = useState({
    confirmDialogToDeleteBibleOpen: false,
    isDeleting: false,
    bibleToRemove: "",
    confirmDialogToMoveBibleOpen: false,
    confirmDialogToCreateCovBibleOpen: false,
    bibleIndexToMove: -1,
    bibleIndexToCreateCovBible: -1,
  });

  const deleteRow = (idToRemove: string) => (): void => {
    if (checkIfBibleIsNew(idToRemove, bibles)) {
      setBibles((currentBibles: Bible[]) => {
        return currentBibles.filter(
          (bible) => bible.id && bible.id !== idToRemove,
        );
      });
      return;
    }

    setDialogState({
      ...dialogState,
      confirmDialogToDeleteBibleOpen: true,
      bibleToRemove: idToRemove,
    });
  };

  const handleConfirmDelete = () => {
    if (checkIfBibleIsToChangeLanguage(dialogState.bibleToRemove, bibles)) {
      setBibles((currentBibles: Bible[]) => {
        return currentBibles.filter(
          (bible) => bible.id && bible.id !== dialogState.bibleToRemove,
        );
      });
      return;
    }

    if (dialogState.bibleToRemove) {
      setDialogState({
        ...dialogState,
        isDeleting: true,
        confirmDialogToDeleteBibleOpen: false,
      });
      deleteBible(dialogState.bibleToRemove)
        .then(() => {
          setBibles((currentBibles: Bible[]) => {
            return currentBibles.filter(
              (bible) => bible.id && bible.id !== dialogState.bibleToRemove,
            );
          });
          setDialogState({
            ...dialogState,
            bibleToRemove: "",
            isDeleting: false,
            confirmDialogToDeleteBibleOpen: false,
          });
        })
        .catch((error: unknown) => {
          setDialogState({
            ...dialogState,
            bibleToRemove: "",
            isDeleting: false,
            confirmDialogToDeleteBibleOpen: false,
          });
          let errorMessage = "There was an error deleting.";

          if (error instanceof AxiosError && error.response) {
            errorMessage = error.response.data?.error || errorMessage;
          } else if (error instanceof Error) {
            errorMessage = error.message;
          }
          showMessage(errorMessage);
          console.log(error);
        });
    } else {
      setDialogState({ ...dialogState, confirmDialogToDeleteBibleOpen: false });
    }
  };

  const handleCancelDelete = () => {
    setDialogState({
      ...dialogState,
      confirmDialogToDeleteBibleOpen: false,
      bibleToRemove: "",
    });
  };

  const handleCancelMoveBible = () => {
    setDialogState({
      ...dialogState,
      confirmDialogToMoveBibleOpen: false,
      bibleIndexToMove: -1,
    });
  };

  const handleCancelCreateCovBible = () => {
    setDialogState({
      ...dialogState,
      confirmDialogToCreateCovBibleOpen: false,
      bibleIndexToCreateCovBible: -1,
    });
  };

  const [editingBible, setEditingBible] = useState<number>();
  const [isFetchingBible, setIsFetchingBible] = useState<boolean>(false);
  const { canAdminBibles } = useUserInfo();

  const [bibles, setBibles] = useState<Bible[]>(retrievedBibles);
  const [isSavingBible, setIsSavingBible] = useState<boolean>(false);
  const biblesRef = useRef<Bible[]>(bibles);
  const prevRetrievedBiblesRef = useRef<Bible[]>(bibles);
  const messages = {
    DUPLICATE_BIBLE: "Bible ID already exists.",
    NO_VALID_PREFIX_BIBLE_ID:
      "The Bible ID is not valid because the length of the Bible ID is less than 6.",
    NAME_REQUIRED: "Bible Name is required.",
    ID_REQUIRED: "Bible ID is required.",
    SAVE_ERROR: "There was an error saving.",
    FETCH_ERROR: "There was an error fetching the Bible ID.",
  };

  useEffect(() => {
    if (!isEqual(retrievedBibles, prevRetrievedBiblesRef.current)) {
      setBibles(retrievedBibles);
    }
    prevRetrievedBiblesRef.current = retrievedBibles;
  }, [retrievedBibles]);

  useEffect(() => {
    biblesRef.current = bibles;
  }, [bibles]);

  const addBible = () => {
    // Check if there's already a new bible being added
    if (bibles?.some((element) => element.isNew)) {
      return; // Only allow adding one new bible at a time
    }

    setBibles((prevState) => {
      setEditingBible(canAdminBibles && prevState ? prevState.length : 0);
      return [
        ...prevState,
        {
          // Add a new bible object
          id: prefixBible(),
          languageId: languageId ? languageId : 0,
          name: "",
          localizedName: "",
          translator: "",
          translationYear: "",
          acronym: "",
          isNew: true,
        },
      ];
    });

    setSelectedBible("");
  };

  const prefixBible = useCallback(
    (): string => (languageIso ? languageIso.toUpperCase() : ""),
    [languageIso],
  );

  const handleInputChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      changedIndex: number,
    ) => {
      setEditingBible(changedIndex);

      setBibles((currentBibles) => {
        const updatedBibles = currentBibles.map((bible, index) => {
          if (changedIndex === index) {
            const newValue = event.target.value;
            switch (event.target.name) {
              case "bibleId":
                return { ...bible, id: newValue.toUpperCase() };
              case "name":
                return { ...bible, name: newValue };
              case "localizedName":
                return { ...bible, localizedName: newValue };
              case "translator":
                return { ...bible, translator: newValue };
              case "translationYear":
                return /^\d{0,4}$/g.test(newValue)
                  ? { ...bible, translationYear: newValue }
                  : bible;
              case "acronym":
                return { ...bible, acronym: newValue };
              default:
                return bible;
            }
          }
          return bible;
        });

        return updatedBibles;
      });
    },
    [setEditingBible],
  );

  const isValidBibleId = useCallback(
    (bibleId: string) => bibleId.length > 5,
    [],
  );

  const cancelEdits = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setEditingBible(undefined);
    },
    [setEditingBible],
  );

  const handleIdBlur = useCallback(
    (bibleId: string, index: number): void => {
      if (isDuplicateId(biblesRef.current, bibleId, index)) {
        showMessage(messages.DUPLICATE_BIBLE);
        return;
      }

      if (!isValidBibleId(bibleId)) {
        showMessage(messages.NO_VALID_PREFIX_BIBLE_ID);
        return;
      }

      setIsFetchingBible(true);
      getBible(bibleId)
        .then((response) => {
          setBibles((currentBibles) => {
            const updatedBibles = currentBibles.map(
              (bible, indexCurrentBible) => {
                if (indexCurrentBible === index) {
                  return {
                    ...bible,
                    name: response.name,
                    localizedName: response.localizedName,
                    translator: response.translator,
                    translationYear: response.date ?? "",
                    moveToLanguage: true,
                  };
                }
                return bible;
              },
            );
            return updatedBibles;
          });
          setIsFetchingBible(false);
        })
        .catch(() => {
          setIsFetchingBible(false);
        });
    },
    [
      showMessage,
      isValidBibleId,
      messages.DUPLICATE_BIBLE,
      messages.NO_VALID_PREFIX_BIBLE_ID,
      getBible,
    ],
  );

  const { Post: stockNumberPost } = useStockNumberService();

  const saveEdits = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement> | null,
      index: number,
    ) => {
      event?.stopPropagation();

      const bible = biblesRef.current[index];
      if (!bible) return;

      const { name, id, moveToLanguage, isNew } = bible;

      if (!name) {
        showMessage(messages.NAME_REQUIRED);
        return;
      }
      if (!id) {
        showMessage(messages.ID_REQUIRED);
        return;
      }
      if (isDuplicateId(biblesRef.current, id, index)) {
        showMessage(messages.DUPLICATE_BIBLE);
        return;
      }
      if (!isValidBibleId(id)) {
        showMessage(messages.NO_VALID_PREFIX_BIBLE_ID);
        return;
      }

      if (moveToLanguage && !dialogState.confirmDialogToMoveBibleOpen) {
        setDialogState({
          ...dialogState,
          confirmDialogToMoveBibleOpen: true,
          bibleIndexToMove: index,
        });
        return;
      }

      if (
        isNew &&
        !dialogState.confirmDialogToCreateCovBibleOpen &&
        isCovBible(id)
      ) {
        setDialogState({
          ...dialogState,
          confirmDialogToCreateCovBibleOpen: true,
          bibleIndexToCreateCovBible: index,
        });
        return;
      }

      setIsSavingBible(true);

      const serviceCall = isNew
        ? moveToLanguage
          ? updateBible(bible)
          : createBible(languageId, bible)
        : updateBible(bible);

      try {
        const response = await serviceCall;

        // if the Bible is a Covenant Bible we need to generate Covenant Film metadata
        if (isNew && isCovBible(id)) {
          const stocknumber = `S2${languageIso?.toUpperCase()}/${BIBLE_SUFFIX_COV}`;

          await stockNumberPost({
            bibleId: id,
            stockNumber: stocknumber,
          });
        }

        setEditingBible(undefined);
        setIsSavingBible(false);
        getLanguageDetails();
        setSelectedBible(response.id);
        setDialogState({
          ...dialogState,
          confirmDialogToMoveBibleOpen: false,
          confirmDialogToCreateCovBibleOpen: false,
          bibleIndexToMove: -1,
          bibleIndexToCreateCovBible: -1,
        });
      } catch (e: unknown) {
        console.error(e);
        setIsSavingBible(false);
        showMessage(messages.SAVE_ERROR);
        setDialogState({
          ...dialogState,
          confirmDialogToMoveBibleOpen: false,
          confirmDialogToCreateCovBibleOpen: false,
          bibleIndexToMove: -1,
          bibleIndexToCreateCovBible: -1,
        });
      }
    },
    [
      showMessage,
      isValidBibleId,
      setSelectedBible,
      messages.SAVE_ERROR,
      messages.DUPLICATE_BIBLE,
      messages.NAME_REQUIRED,
      messages.ID_REQUIRED,
      messages.NO_VALID_PREFIX_BIBLE_ID,
      getLanguageDetails,
      languageId,
      languageIso,
      dialogState,
      updateBible,
      createBible,
      stockNumberPost,
    ],
  );

  const handleConfirmMoveBible = (): void => {
    saveEdits(null, dialogState.bibleIndexToMove);
  };

  const handleConfirmCreateCovBible = (): void => {
    setDialogState({
      ...dialogState,
      confirmDialogToCreateCovBibleOpen: false,
    });
    saveEdits(null, dialogState.bibleIndexToCreateCovBible);
  };

  return (
    <>
      <Backdrop
        sx={styles.backdrop}
        open={isSavingBible || dialogState.isDeleting || isFetchingBible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialogToDeleteBible
        open={dialogState.confirmDialogToDeleteBibleOpen}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
      <ConfirmDialogToMoveBible
        open={dialogState.confirmDialogToMoveBibleOpen}
        onConfirm={handleConfirmMoveBible}
        onCancel={handleCancelMoveBible}
      />
      <ConfirmDialogToCreateCovBible
        open={dialogState.confirmDialogToCreateCovBibleOpen}
        onConfirm={handleConfirmCreateCovBible}
        onCancel={handleCancelCreateCovBible}
      />
      <Grid container justifyContent="space-between" alignItems="baseline">
        <Grid item xs={10}>
          <Typography variant="h6" sx={styles.subSectionTitle}>
            Bibles
          </Typography>
        </Grid>
        <Grid item xs={2} sx={styles.alignRight}>
          {canAdminBibles && languageId ? (
            <Button color="secondary" variant="outlined" onClick={addBible}>
              Add Bible
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "90px" }}>
                  ID
                  <Tooltip title="ID Required">
                    <Typography
                      color="primary"
                      variant="body1"
                      sx={{ display: "inline" }}
                    >
                      *&nbsp;
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Name
                  <Tooltip title="Name Required">
                    <Typography
                      color="primary"
                      variant="body1"
                      sx={{ display: "inline" }}
                    >
                      *&nbsp;
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>Localized Name</TableCell>
                <TableCell sx={{ width: "200px" }}>Translator</TableCell>
                <TableCell sx={{ width: "60px" }}>Year</TableCell>
                {canAdminBibles && <TableCell>{/* controls */}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {bibles?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ opacity: ".7" }}>
                    No results.
                  </TableCell>
                </TableRow>
              )}
              {bibles?.length > 0 &&
                bibles?.map((bible: Bible, index: number) => (
                  <BibleComponent
                    key={`bibles_bible_row_${index}`}
                    bibleId={bible.id}
                    name={bible.name}
                    localizedName={bible.localizedName}
                    translator={bible.translator}
                    translationYear={bible.translationYear}
                    index={index}
                    isSelected={
                      selectedBible === bible.id ||
                      bible?.isNew === true ||
                      bibles?.length === 1
                    }
                    isNew={bible.isNew ?? false}
                    canAdminBibles={canAdminBibles}
                    isEditingBible={editingBible === index}
                    setSelectedBible={setSelectedBible}
                    handleInputChange={handleInputChange}
                    handleIdBlur={handleIdBlur}
                    saveEdits={saveEdits}
                    cancelEdits={cancelEdits}
                    deleteRow={deleteRow}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default Bibles;
